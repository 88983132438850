import { Injectable } from '@angular/core';

import { EScrollPrefix } from '../enums/scroll-to.enum';

@Injectable({
  providedIn: 'root',
})
export class ScrollToIdService {
  public getOffset(): number {
    return 90;
  }

  public scrollToItineraryInfo(id: number, prefix: EScrollPrefix): void {
    const scrollToEl = document.getElementById(`${prefix + id}`);
    const scrollToTop = scrollToEl.offsetTop - this.getOffset();
    window.scrollTo({ top: scrollToTop, behavior: 'smooth' });
  }

  public scrollTo(
    id: number,
    prefix: EScrollPrefix,
    offset: number = this.getOffset(),
  ): void {
    const scrollToEl = document.getElementById(`${prefix + id}`);
    if (scrollToEl) {
      const rect = scrollToEl.getBoundingClientRect();
      const scrollToTop = rect.top + window.scrollY - offset;
      window.scrollTo({ top: scrollToTop, behavior: 'smooth' });
    } else {
      console.error('Element not found');
    }
  }

  public scrollToElement(
    scrollToEl: Element | HTMLElement,
    offset: number = this.getOffset(),
  ): void {
    if (scrollToEl) {
      const rect = scrollToEl.getBoundingClientRect();
      const scrollToTop = rect.top + window.scrollY - offset;
      window.scrollTo({ top: scrollToTop, behavior: 'smooth' });
    } else {
      console.error('Element not found');
    }
  }

  public scrollToWithContainer(
    id: number,
    prefix: any | EScrollPrefix,
    scrollContainerSelector: string,
    offset: number = 50,
  ): void {
    const scrollToEl = document.getElementById(`${prefix + id}`);

    if (!scrollToEl) return;

    const scrollContainer = scrollToEl.closest(scrollContainerSelector);

    if (scrollContainer) {
      const elementOffsetTop =
        scrollToEl.getBoundingClientRect().top -
        scrollContainer.getBoundingClientRect().top;
      setTimeout(() => {
        scrollContainer.scrollTo({
          top: scrollContainer.scrollTop + elementOffsetTop - offset,
          behavior: 'smooth',
        });
      }, 50);
    } else {
      this.scrollTo(id, prefix);
    }
  }

  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  scrollToBottom(): void {
    window.scrollTo({
      top: window.document.body.clientHeight,
      behavior: 'smooth',
    });
  }

  public disableScrollEvent(): void {
    window.addEventListener('touchmove', this.preventDefault, {
      passive: false,
    });
  }

  public enableScrollEvent(): void {
    window.removeEventListener('touchmove', this.preventDefault, false);
  }

  private preventDefault(ev: Event): void {
    ev.preventDefault();
  }
}
